import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Family, FamilyRight } from '../models/family';
import { PictureService } from '../models/scope-management.model';
import { CacheDeltaResponse } from '../models/cache';
import { CHUNK_SIZE, FileDescriptor, ProtectedSignURLResponse, UploadPart } from '../models/picture';
import { FileService } from './file.service';

export interface CreateOrUpdateFamily {
    id?: string;
    name: string;
    description: string;
    capacity?: number;
    compatibleSection?: string[];
    pn: string[];
    isJig?: boolean;
    category: string;
    signalSource?: string[];
    isNotManagedByWay?: boolean;
    minMaxTime: number;
    isAirplan?: boolean;
    isCargo?: boolean;
}

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class FamilyService implements PictureService {

    private http = inject(HttpClient);
    private fileService = inject(FileService);

    public getFamilies(delta?: string): Observable<CacheDeltaResponse<Family>> {
        let params = new HttpParams();

        if (delta !== undefined) {
          params = params.set('delta', delta);
        }
        return this.http.get<CacheDeltaResponse<Family>>(
            `${environment.API_ENDPOINT}/families`
            ,{ params }
        );
    }

    public getFamiliesRights(delta?: string): Observable<CacheDeltaResponse<FamilyRight>> {
        let params = new HttpParams();

        if (delta !== undefined) {
          params = params.set('delta', delta);
        }

        return this.http.get<CacheDeltaResponse<FamilyRight>>(
            `${environment.API_ENDPOINT}/families-rights`
            ,{ params }
        );
    }

    public getUserFamiliesRights(userId: string): Observable<FamilyRight[]> {
        return this.http.get<FamilyRight[]>(
            `${environment.API_ENDPOINT}/families-rights/users/${userId}`
        );
    }

    public getPnByFamilies(familyId: string): Observable<string[]> {
        return this.http.get<string[]>(`${environment.API_ENDPOINT}/families/${familyId}/pn`);
    }

    public getPicture(familyId: string): Observable<{ picture: string }> {
        return this.http.get<{ picture: string }>(`${environment.API_ENDPOINT}/families/${familyId}/picture`);
    }


    addFamilyPictureGetSignedURL(body: any, familyId: string) {
        return this.http.post<any>(
            `${environment.API_ENDPOINT}/families/${familyId}/picture/get-signed-url`,
            {
                fileId: body.fileId,
                fileKey: body.fileKey,
                parts: body.parts
            },
            httpOptions
        );
    }

    public deletePictureFamily(familyId: string): Observable<Family> {
        return this.http.delete<Family>(`${environment.API_ENDPOINT}/families/${familyId}/picture`);
    }

    public createFamily(family: CreateOrUpdateFamily, parentFamilyId: string): Observable<Family> {
        const body = { ...family, parentFamilyId: parentFamilyId };
        return this.http.post<Family>(
            `${environment.API_ENDPOINT}/families`,
            JSON.stringify(body),
            httpOptions
        );
    }

    public updateFamily(family: CreateOrUpdateFamily, familyId: string): Observable<Family> {
        return this.http.put<Family>(
            `${environment.API_ENDPOINT}/families/${familyId}`,
            JSON.stringify(family),
            httpOptions
        );
    }

    public deleteFamily(familyId: string): Observable<Family> {
        return this.http.delete<Family>(`${environment.API_ENDPOINT}/families/${familyId}`);
    }

    public createPictureFamily(file: File, familyId: string): Observable<Family> {
        const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

        // Start with initial progress for the first API call
        this.fileService.updateProgress(1);

        return this.http.post<FileDescriptor>(
            `${environment.API_ENDPOINT}/families/${familyId}/picture`,
            { picture: file.name, pictureType: file.type },
            httpOptions
        ).pipe(
            tap(() => this.fileService.updateProgress(10)), // 10% after the first API

            switchMap((fileDescriptor) => {
                const body = {
                    fileId: fileDescriptor.fileId,
                    fileKey: fileDescriptor.fileKey,
                    parts: totalChunks
                };
                return this._generateSignedURLForFamilyPicture(body, familyId).pipe(
                    tap(() => this.fileService.updateProgress(20)), // 20% after the second API

                    switchMap((signedUrls) =>
                        this.fileService.uploadMultipartFile(file, signedUrls.map((url) => url.signedUrl))
                    ),

                    switchMap(uploadPartsArray => this._finalizeFamilyPictureUpload(
                        fileDescriptor.fileId,
                        fileDescriptor.fileKey,
                        uploadPartsArray,
                        familyId
                        ).pipe(
                            tap(() => {
                                // Smooth transition from 90% to 100% after the final API call
                                const interval = setInterval(() => {
                                    const currentProgress = this.fileService.getProgress();
                                    if (currentProgress < 100) {
                                        this.fileService.updateProgress(currentProgress + 2); // Increment by 2% per step
                                    } else {
                                        clearInterval(interval); // Stop at 100%
                                    }
                                }, 100); // Update every 100ms
                            })
                        )
                    )
                );
            })
        );
    }

    private _generateSignedURLForFamilyPicture(payload: FileDescriptor, familyId: string): Observable<ProtectedSignURLResponse[]> {
        return this.http.post<ProtectedSignURLResponse[]>(
            `${environment.API_ENDPOINT}/families/${familyId}/picture/signed-url`, payload);
    }

    private _finalizeFamilyPictureUpload(uploadId: string, fileKey: string, partsArray: UploadPart[], familyId: string): Observable<Family> {
        partsArray.sort((a, b) => a.PartNumber - b.PartNumber);
        return this.http.post<Family>(
            `${environment.API_ENDPOINT}/families/${familyId}/picture/finalize-file`,
            {
                fileId: uploadId,
                fileKey: fileKey,
                partsArray,
            }
        );
    }
}
