import { DialogUploadPlanningComponent } from '../dialog/upload-planning-dialog/dialog-upload-planning.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { deletePlannedMovement, fetchAllAircraftPlanning, successDeletePlannedMovementClash } from 'src/app/store/actions/aircraft-planning.action';
import { selectAircraftPlanning$, selectAircraftPlanningState } from 'src/app/store/selectors/aircraft-planning.selectors';
import { AircraftPlanning, AircraftPlanningUpload, PlanningCount } from 'src/app/models/aircraft-planning';
import { Observable, Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { selectProfileUserRights$ } from 'src/app/store/selectors/profile.selectors';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { selectAircraftPlanningFilters$ } from 'src/app/store/selectors/filter.selectors';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { DialogEditAircraftPlanningComponent } from '../dialog/dialog-edit-aircraft-planning/dialog-edit-aircraft-planning.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogValidationComponent } from '../../shared/dialog/dialog-validation/dialog-validation.component';
import { AircraftPlanningEffects } from 'src/app/store/effects/aircraft-planning.effects';
import { selectZones$ } from 'src/app/store/selectors/zone.selector';
import { contextIsValid$, selectUserSelectedContext$ } from 'src/app/store/selectors/filter-context.selectors';

@Component({
  selector: 'app-aircraft-planning',
  templateUrl: './aircraft-planning.component.html',
  styleUrls: ['./aircraft-planning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AircraftPlanningComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) private _sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) private _paginator: MatPaginator;
  @ViewChild('table', { read: ElementRef }) private _table: ElementRef<HTMLTableElement>;

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public view = 'aircraftPlanningView';
  public aircraftPlanning$: Observable<Array<AircraftPlanning>>;
  public loader = true;
  public dataSource = new MatTableDataSource<AircraftPlanning>();
  public displayedColumns: string[] = ['msn', 'program', 'version', 'airline', 'currentPosition', 'futurePosition', 'arrivalTime', 'status', 'actions'];
  public trackerPlanningManager: boolean;
  public dateForm: UntypedFormGroup;
  public aircraftPlanningCount: PlanningCount;
  public clashMode = false;
  public messagesClashes: Record<string, string[]> = {};
  public moment = moment;
  public contextIsValid$ = this.store.select(contextIsValid$);

  private _originalDataSource: Array<AircraftPlanning>;
  private _subscription: Subscription = new Subscription();

  constructor(
    private cd: ChangeDetectorRef,
    private _dialog: MatDialog,
    private store: Store,
    private _translate: TranslateService,
    private _aircraftPlanningEffects: AircraftPlanningEffects,
  ) { }

  ngOnInit(): void {
    this._initDateForm();
    this._initRights();
    this._initData();
    this._initTable();
  }

  /**
   * Initialize the form for date selection
   * By default, the range is +/- one week
   */
  private _initDateForm() {
    this.dateForm = new UntypedFormGroup({
      fromDate: new UntypedFormControl({ value: moment().subtract(1, 'weeks').startOf('day'), disabled: false }, Validators.required),
      toDate: new UntypedFormControl({ value: moment().add(1, 'weeks').endOf('day'), disabled: false }, Validators.required)
    });
  }

  /**
   * Init the user rights to enable or not edition
   */
  private _initRights() {
    this._subscription.add(this.store.select(selectProfileUserRights$).subscribe((userRight) => {
      this.trackerPlanningManager = userRight.isPlanningManagerAct;
    }));
  }

  /**
   * Init the data needed for the component creation
   */
  private _initData() {
    this.aircraftPlanning$ = this.store.select(selectAircraftPlanningState).pipe(
      filter(aircraftPlanningState => aircraftPlanningState.loaded),
      map(aircraftPlanning => aircraftPlanning.data)
    );

    // Table datasource, containing filtered aircraft planning data
    this._subscription.add(this.store.select(selectAircraftPlanningFilters$).subscribe((aircraftPlanning: Array<AircraftPlanning>) => {
      this.loader = false;
      this._originalDataSource = aircraftPlanning;
      this.dataSource.data = aircraftPlanning.sort((pe1, pe2) => {
        if (moment(pe1.arrivalTime).isBefore(moment(pe2.arrivalTime))) {
          return -1;
        } else {
          return 1;
        }
      });
      this._initCount(aircraftPlanning);
      this.dataSource.data.forEach((row: AircraftPlanning) => {
        this.messagesClashes[row.id] = [];
        row.clashes?.forEach((clash) => {
          let message = this._translate.instant(clash.message);
          if (clash.message === 'CONFLICT_OTHER_LINE') {
            message += ` ${clash.program} - ${clash.msn}`;
          }
          this.messagesClashes[row.id].push(message);
        });
      });
    }));
    this._subscription.add(this._aircraftPlanningEffects.effectClashSubject.subscribe((clashMode) => {
      this.clashMode = clashMode;
    }));

    // Fetch data on context change
    this._subscription.add(
        this.store.select(selectUserSelectedContext$).pipe(
            filter(v => !!v),
            distinctUntilChanged(),
        ).subscribe(
            () => this.refreshData()
        )
    );
  }

  /**
   * Loop on the aircraft planning array to identify error and clash items
   * @param aircraftPlanning The array of aircraft planning item
   */
  private _initCount(aircraftPlanning: Array<AircraftPlanning>) {
    this.aircraftPlanningCount = { all: 0, error: 0, clash: 0 }; // Reset count on each refresh
    this.aircraftPlanningCount.all = aircraftPlanning.length;
    aircraftPlanning.forEach(item => {
      if (item.status?.type === 'error') {
        this.aircraftPlanningCount.error++;
      } else if (item.clashes?.length) {
        this.aircraftPlanningCount.clash++;
      }
    });
  }

  /**
   * Init the zones mat table
   */
  private _initTable() {
    this.dataSource.sort = this._sort;
    this.dataSource.paginator = this._paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'currentPosition': return item['currentPosition']?.name;
        case 'futurePosition': return item['futurePosition']?.name;
        default: return item[property];
      }
    };
    this._subscription.add(
        this.dataSource.connect().subscribe(
            () => this.cd.markForCheck()
        )
    );
  }

  /**
   * Filter the original filtered aircraft planning data with live filtering, assign result to table datasource
   * @param type Selected type to filter on, can be all, error or clash
   */
  public liveFilterAircraftPlanning(type: keyof typeof this.aircraftPlanningCount) {
    switch (type) {
      case 'all':
        this.dataSource.data = this._originalDataSource;
        break;
      case 'error':
        this.dataSource.data = this._originalDataSource.filter((aircraftPlanning: AircraftPlanning) => aircraftPlanning.status?.type === type);
        break;
      case 'clash':
        this.dataSource.data = this._originalDataSource.filter((aircraftPlanning: AircraftPlanning) => aircraftPlanning.clashes?.length);
        break;
    }
  }

  /**
   * Refresh on demand the aircraft planning data
   * Take into account the actual from / to date from the datepicker form control
   */
  public refreshData() {
    if (this.dateForm?.controls?.fromDate?.value && this.dateForm?.controls?.toDate?.value) {
      this.loader = true;
      this.store.dispatch(fetchAllAircraftPlanning({
        fromDate: this.dateForm.controls.fromDate.value.startOf('day').toISOString(),
        toDate: this.dateForm.controls.toDate.value.endOf('day').toISOString()
      }));
    }
  }

  /**
   * Scroll up on mat table
   */
  public scrollUp() {
    this._table.nativeElement.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);
  }

  /**
   * Open a popup to upload a new planning
   */
  public uploadAircraftPlanning() {
    let aircraftPlanning: AircraftPlanningUpload[] = [];
    this._subscription.add(this.store.select(selectAircraftPlanning$).pipe(take(1)).subscribe((data) => {
      this._subscription.add(this.store.select(selectZones$).pipe(take(1)).subscribe((zones) => {
        aircraftPlanning = data.map(d => {
          const zoneFromName = zones.find(z => z.id === d.zoneFrom)?.name;
          const zoneToName = zones.find(z => z.id === d.zoneTo)?.name;
          return {
            date: d.arrivalTime,
            msn: d.msn,
            version: d.version,
            program: d.program,
            siteFrom: d.siteFrom,
            siteTo: d.siteTo,
            zoneFrom: zoneFromName,
            zoneTo: zoneToName,
          };
        });
      }));
    }));
    this._dialog.open(DialogUploadPlanningComponent, this.clashMode ? {
      data: {
        planning: aircraftPlanning,
        onClashValidated: () => this.refreshData()
      },
      width: '60vw'
    } : {});
  }

  /**
   * Open a popup to update a planning line
   * @param element The aircraft planning line to update
   */
  public openEditDialog(element: AircraftPlanning) {
    this._dialog.open(DialogEditAircraftPlanningComponent, {
      data: { ...element, clashMode: this.clashMode },
      width: '20vw',
      minWidth: '20em',
    });
  }

  /**
   * Open a popup to update a planning line
   * @param element The aircraft planning line to update
   */
  public deleteEditDialog(element: AircraftPlanning) {
    const dialogRef: MatDialogRef<DialogValidationComponent> = this._dialog.open(DialogValidationComponent, {
      minWidth: '250px',
      data: {
        title: this._translate.instant('DIALOG_DELETE_OPERATION_TITLE'),
        textContent: this._translate.instant('DIALOG_DELETE_VALIDATION', { item: '' }),
        validateText: this._translate.instant('DELETE'),
        cancelText: this._translate.instant('CANCEL'),
      }
    });
    this._subscription.add(dialogRef.componentInstance.onValidateEvent.subscribe(() => {
      this._deletePlannedMovementProcess(element, dialogRef);
    }));
  }

  /**
   * On callback of delete popup, delete aircraft planning operation
   * @param element The operation aircraft planning to delete
   * @param dialogRef The generic validation dialog component
   */
  private _deletePlannedMovementProcess(element: AircraftPlanning, dialogRef: MatDialogRef<DialogValidationComponent>) {
    if (this.clashMode) {
      this.store.dispatch(successDeletePlannedMovementClash({ payload: element }));
      dialogRef.componentInstance.finishProcess();
    } else {
      this._subscription.add(
        this._aircraftPlanningEffects.effectSubject.pipe(take(1)).subscribe((result) => {
          if (result) {
            dialogRef.componentInstance.finishProcess();
          }
        }));
      this.store.dispatch(deletePlannedMovement({ aircraftPlanning: element }));
    }
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
